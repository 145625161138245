/*
 * @Description:
 * @Author: yanxiao
 * @Github: https://github.com/yanxiaos
 * @Date: 2021-07-08 14:33:36
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'
import qs from 'qs'

const prefix = '/mobile'

export function queryWxConfigData (param) {
  return request({
    url: prefix + '/common/getSignature',
    method: 'post',
    data: qs.stringify(param),
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  })
}
