/*
 * @Description:
 * @Author: yanxiao
 * @Github: https://github.com/yanxiaos
 * @Date: 2021-07-08 11:01:20
 * @LastEditors: zhoucheng
 */
import { queryWxConfigData } from '@/api/queryDict/wxConfig.js'
import wx from 'weixin-jsapi'
/**
 * @name: wx config
 * @description: 配置 wx config 返回一个 Promise对象
 * @param {type} {*} jsApiList
 * @return {type} {*} 返回一个 Promise对象 then(()=>{}), catch(err=>{})
 */

/**
 * jsApiList: [
 * chooseWXPay,
 * getLocation,
 * openLocation
 * ]
 */
// 微信签名查询接口 wx config
export default function (jsApiList) {
  return new Promise((resolve, reject) => {
    const url = location.href.split('#')[0]
    const info = {
      url: url
    }
    // 通过location.href.split("#")[0]截取之后的url
    queryWxConfigData(info).then(res => {
      // 调用微信配置接口接口
      wx.config({
        // appId: 'wxa0ccb4f988931c11', // 必填，公众号的唯一标识 内网
        appId: 'wxaa4bd9cc425e1619', // 必填，公众号的唯一标识 线上
        timestamp: Number(res.resultEntity.timestamp), // 必填，生成签名的时间戳 注意:需要转成int类型
        nonceStr: res.resultEntity.nonceStr, // 必填，生成签名的随机串
        signature: res.resultEntity.signature, // 必填，签名
        jsApiList // 必填，需要使用的JS接口列表 这里需要使用使用微信内置地图查看位置接口.获取地理位置接口 jssdk中的8.1 8.2
      })
      wx.ready(function () {
        console.log('签名配置完成')
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        resolve()
      })
      wx.error(function (err) {
        console.log('wxConfig 配置失败', err)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        reject(err)
      })
    }).catch(err => {
      console.log('获取签名请求失败', err)
      reject(err)
    })
  })
}
